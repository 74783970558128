import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const CompliancePage = () => {
  return (
    <Layout>
      <Seo title='Sara Fashionwear'/>
      <div className="px-4 sm:px-6 lg:px-10 pt-12">
      <p className="text-lg font-bold">Terms & Conditions</p>
      <p className="pb-2">Last updated: Jan 27, 2022</p>
      <p className="pb-2">Please read these terms and conditions carefully before using Our Service.</p>
      <p className="pb-2">Interpretation and Definitions</p>
      <p className="pb-2">Interpretation</p>
      <p className="pb-2">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
      <p className="pb-2">Definitions</p>
      <p className="pb-2">For the purposes of these Terms and Conditions:</p>
      <p className="pb-2">Country refers to: Bangladesh</p>
      <p className="pb-2">Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Sara Fashionwear, Montribari Road, South Salna, Salna Bazar, Gazipur, 1703, Bangladesh.</p>
      <p className="pb-2">Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
      <p className="pb-2">Service refers to the Website.</p>
      <p className="pb-2">Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.
Third-party Social Media Service means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
Website refers to Sara Fashionwear, accessible from http://www.sarafashionwear.com
You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
</p>
      <p className="pb-2">Acknowledgements</p>
      <p className="pb-2">These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service. Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service. By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service. Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You share it with Us through our contact form or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>
      <p className="pb-2">Intellectual Property</p>
      <p className="pb-2">The Service and its original content (excluding Content provided by You or other users), features and functionality are and will remain the exclusive property of the Company and its licensors. The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company. All media, such as photographs and video content is not for public use as it belongs to Sara Fashionwear Ltd. It may not be used by third parties for any reason without permission. Legal measures will be taken if this rule is not respected.</p>
      <p className="pb-2">Links to other Websites</p>
      <p className="pb-2">Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</p>
      <p className="pb-2">Governing Law</p>
      <p className="pb-2">The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.</p>
      <p className="pb-2">Changes to These Terms and Conditions</p>
      <p className="pb-2">We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion. By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>
      <p className="pb-2">Contact Us</p>
      <p className="pb-2">If you have any questions about these Terms and Conditions, You can contact us: By email: info@sarafashionwear.com</p>
      </div>
    </Layout>
  )}

export default CompliancePage